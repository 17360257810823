import { Link } from 'react-scroll';

export default function Footer(): JSX.Element {
    return (
    <footer className="bg-[#1F2937] text-white pb-28">
      <div className="container mx-auto py-8 flex flex-col md:flex-row justify-between items-center">
        {/* Informações de contato */}
        <div className="mb-4 md:mb-0">
          <p className="text-lg font-semibold">Contato:</p>
          <p>Email: vidroluz.rio@gmail.com</p>
          {/* Ícone de e-mail */}
        <div className="mb-4 md:mb-0 mt-4">
          <a
            href="mailto:vidroluz.rio@gmail.com"
            className="flex items-center text-lg font-semibold"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 mr-2"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16m-8 6h16"
              />
            </svg>
            Enviar e-mail
          </a>
        </div>
        </div>

        {/* Links para partes da página */}
        <div className="flex flex-col">
        <Link to="aboutus" smooth className="text-lg font-semibold hover:underline cursor-pointer">
          Sobre nós
        </Link>
        <Link to="services" smooth className="text-lg font-semibold hover:underline cursor-pointer">
          Produtos e serviços
        </Link>
        <Link to="contacts" smooth className="text-lg font-semibold hover:underline cursor-pointer">
          Contatos
        </Link>
        <a className='mt-4'>Todos os direitos reservados à Vidroluz Vidraçaria</a>
        </div>
      </div>
    </footer>
  );
};