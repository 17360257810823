import React, { useContext } from 'react';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import './miniSlide.css'
import { buttonImages } from './buttonTexts';
import GlobalContext from '../context/GlobalContext';

SwiperCore.use([Navigation, Pagination]);

const MiniSlide: React.FC = () => {
    const { serviceSlide } = useContext(GlobalContext);

  // Verificar se o nome do botão existe como chave em buttonImages
  const imagesArray = serviceSlide in buttonImages ? buttonImages[serviceSlide] : [];
  

  return (
    <Swiper
      spaceBetween={30}
      slidesPerView={1}
      navigation
      pagination={{ clickable: true }}
      style={{borderRadius: '10px'}}
    >
      {imagesArray.length === 0 ? 'Logo atualizaremos com novos trabalhos feitos pela Vidroluz!' : imagesArray.map((imageUrl: string, index: number) => (
        <SwiperSlide 
            key={index}
        >
          <div className='flex items-center justify-center'>
            <img
              src={imageUrl}
              alt={`Imagem ${index + 1}`}
            />
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default MiniSlide;
