import { Link } from 'react-scroll';

export default function Header(): JSX.Element {
  return (
    <header>
      <div className='p-2 bg-[#1F2937] flex items-center justify-around text-white'>
        <a href='https://wa.me/5521984719548'>
          <div className='flex items-center'>
            <img src='https://i.imgur.com/P3gb7vP.png' alt='whatsapp icon' className='w-7 mr-2' />
            <p className='hover-underline-wpp'>(21) 98471-9548</p>
          </div>
        </a>
        <p></p>
        <p>vidroluz.rio@gmail.com</p>
      </div>
      <div className="flex items-center justify-around">
        <img src='https://imgur.com/Gw93XhD.png' alt='Vidroluz Logo' className="w-1/5" />
        <div className="text-[#161616] font-lg">
        <Link to="aboutus" smooth className="hover-underline-animation mr-10 ml-10 cursor-pointer">
          Sobre nós
        </Link>
        <Link to="services" smooth className="hover-underline-animation mr-10 ml-10 cursor-pointer">
          Produtos e serviços
        </Link>
        <Link to="contacts" smooth className="hover-underline-animation mr-10 ml-10 cursor-pointer">
          Contatos
        </Link>
        </div>
      </div>
    </header>
  );
}