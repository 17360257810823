import { useState, ReactNode } from 'react';
import globalContext from './GlobalContext';

interface GlobalProviderProps {
    children: ReactNode;
  }
  
  function GlobalProvider({ children }: GlobalProviderProps) {
    const [serviceSlide, setServiceSlide] = useState('espelhos');

  const value = {serviceSlide, setServiceSlide};

  return (
    <globalContext.Provider value={ value }>
      {children}
    </globalContext.Provider>
  );
}

export default GlobalProvider;