import { useState, useContext, useEffect } from 'react';
import { Button, ButtonGroup } from '@mui/material';
import { buttonTexts } from './buttonTexts';
import GlobalContext from '../context/GlobalContext';
import MiniSlide from './MiniSlide';

export default function ServicesType() {
  const [displayTitle, setDisplayTitle] = useState('');
  const [displayDesc, setDisplayDesc] = useState('');
  const [opacity, setOpacity] = useState(1);
  const { setServiceSlide } = useContext(GlobalContext);

  useEffect(() => {
    setDisplayTitle(buttonTexts.one[0]);
    setDisplayDesc(buttonTexts.one[2]);
  }, [])

  const handleButtonClick = (title: string, text: string, desc: string) => {

    setOpacity(0);

    setTimeout(() => {
      setDisplayTitle(title);
      setDisplayDesc(desc);
      setServiceSlide(text);

      setTimeout(() => {
        setOpacity(1);
      }, 250);
    }, 250);
  };

  return (
      <div className="flex justify-center items-center max-w-full mb-28" id="services">
        <ButtonGroup
          variant="outlined" 
          aria-label="outlined button group"
          orientation="vertical"
          className='mb-20'
        >
          {Object.entries(buttonTexts).map(([key, text]) => (
            <Button 
              onClick={() => handleButtonClick(text[0], text[1], text[2])}
              key={key}
              sx={{ fontSize: 'medium'}}>
              {text[0]}
            </Button>
          ))}
        </ButtonGroup>
        <div
        style={{
          opacity: opacity,
          transition: 'opacity 0.2s ease-in-out',
        }}
        className='max-w-[70%] ml-20 w-1/2 text-center flex flex-col justify-center border-dotted border-2 border-indigo-600 p-10 rounded-lg bg-[#1F2937] text-white drop-shadow-lg'>
          <h2 className='font-bold text-3xl mb-6'>{displayTitle}</h2>
          <div
          style={{
            opacity: opacity,
            transition: 'opacity 1s ease-in-out',
          }}
          ><MiniSlide /></div>
          <span className='mt-6 text-justify text-lg max-h-56 overflow-y-auto pr-4'>{displayDesc}</span>
        </div>
      </div>
  );
}
