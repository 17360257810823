import React from 'react';
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';

SwiperCore.use([Navigation, Pagination, Autoplay]);

const SLIDE_HEIGHT = 400;

const slides = [
  {
    imageUrl: 'https://imgur.com/BTJc1xy.jpg',
    title: 'Título da primeira imagem',
    description: 'Descrição da primeira imagem',
  },
  {
    imageUrl: 'https://imgur.com/FOHwpIB.jpg',
    title: 'Título da segunda imagem',
    description: 'Descrição da segunda imagem',
  },
  {
    imageUrl: 'https://imgur.com/r1KLVva.jpg',
    title: 'Título da terceira imagem',
    description: 'Descrição da terceira imagem',
  },
];

const Slide: React.FC = () => {
  return (
    <Swiper
      className='mb-28'
      spaceBetween={50}
      slidesPerView={1}
      navigation
      autoplay
      pagination={{ clickable: true }}
    >
      {slides.map((slide, index) => (
        <SwiperSlide key={index}>
          <div style={{ height: SLIDE_HEIGHT, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <img
              src={slide.imageUrl}
              alt={`Imagem ${index + 1}`}
            />
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default Slide;
