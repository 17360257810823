export const buttonTexts = {
  one: ['Espelhos', 'espelhos', `Com opções em diferentes tamanhos, formatos e designs, nossos espelhos são ideais para salas de estar, quartos, banheiros, academias, comércios e escritórios. Contamos com uma equipe especializada para auxiliar na escolha e instalação, garantindo um atendimento personalizado e resultados satisfatórios. Transforme seus espaços com a elegância e funcionalidade dos nossos espelhos, visite-nos hoje mesmo!
   Descubra a beleza e a versatilidade dos espelhos em nossa vidraçaria. Com qualidade e acabamento impecáveis, nossos espelhos são perfeitos para adicionar estilo e luminosidade aos ambientes. Além de refletirem imagens, eles ampliam visualmente os espaços, valorizando a decoração e destacando detalhes. Com opções para todos os gostos e necessidades, nossos profissionais estão prontos para ajudar na escolha do espelho ideal e garantir uma instalação segura e de qualidade. Renove seus ambientes com a magia dos espelhos, visite-nos agora mesmo!`],
  two: ['Box (Blindex)', 'box', `Descubra a elegância e a praticidade dos Box Blindex em nossa vidraçaria. Com opções disponíveis em diversos tamanhos, acabamentos e designs, nossos Box Blindex são perfeitos para banheiros, proporcionando um visual moderno e sofisticado. Com a qualidade e durabilidade características da marca Blindex, nossos produtos oferecem segurança e conforto, além de um toque de estilo ao ambiente.
  Nossa equipe especializada está pronta para auxiliar na escolha do Box Blindex ideal para o seu banheiro, levando em consideração suas preferências estéticas e as necessidades do espaço. Além disso, garantimos uma instalação segura e de alta qualidade, para que você possa desfrutar da funcionalidade e beleza do seu Box Blindex sem preocupações.
  Com os Box Blindex, você transforma seu banheiro em um ambiente moderno e organizado, aproveitando ao máximo o espaço disponível. Além de delimitar a área do chuveiro, eles também oferecem proteção contra respingos, mantendo o restante do banheiro seco e limpo. Desfrute de momentos relaxantes e revigorantes em um ambiente pensado nos mínimos detalhes.`],
  three: ['Porta de Vidro', 'portaDeVidro', `Descubra a beleza e a versatilidade das portas de vidro em nossa vidraçaria. Com designs elegantes e modernos, nossas portas de vidro são perfeitas para adicionar um toque contemporâneo aos ambientes, além de proporcionarem luminosidade e uma sensação de amplitude.
  Nossas portas de vidro são fabricadas com materiais de alta qualidade, garantindo durabilidade e resistência. Disponíveis em diferentes estilos, como portas de correr, pivotantes e de abrir, oferecemos opções para todos os gostos e necessidades. Com uma variedade de acabamentos e detalhes, você pode personalizar sua porta de vidro de acordo com o estilo da sua casa ou escritório.
  Além de sua estética encantadora, as portas de vidro também trazem benefícios funcionais. Elas permitem a passagem de luz natural, tornando os ambientes mais iluminados e agradáveis. Além disso, oferecem uma conexão visual entre os espaços, criando uma sensação de integração e fluidez.`],
  four: ['Janela de Vidro', 'janelaDeVidro', `Descubra a qualidade e a beleza das janelas de vidro em nossa vidraçaria. Com designs modernos e funcionais, nossas janelas de vidro são perfeitas para trazer iluminação natural e uma vista deslumbrante para os ambientes.
  Nossas janelas de vidro são fabricadas com materiais de alta qualidade, garantindo durabilidade e resistência. Disponíveis em diferentes estilos, como janelas de correr, basculantes e pivotantes, oferecemos opções que se adaptam perfeitamente às suas necessidades e ao estilo da sua casa ou escritório.
  Além de serem visualmente atraentes, as janelas de vidro oferecem benefícios funcionais. Elas permitem a entrada de luz natural, tornando os espaços mais luminosos e agradáveis. Além disso, proporcionam uma conexão visual com o exterior, permitindo desfrutar de belas paisagens e criar uma sensação de integração com o ambiente externo.`],
  five: ['Esquadrias de Alumínio', 'janelaDeAlum', `
  Explore a versatilidade e qualidade das esquadrias de alumínio em nossa empresa especializada. Com designs modernos e funcionais, nossas esquadrias de alumínio são a opção ideal para agregar praticidade e sofisticação aos seus ambientes.
  
  Construídas com materiais de alta durabilidade, nossas esquadrias de alumínio representam sinônimo de resistência e longevidade. Oferecemos uma ampla gama de estilos, incluindo janelas deslizantes, basculantes e maxim-ar, proporcionando opções que se adaptam perfeitamente às suas preferências estéticas e às necessidades específicas de sua residência ou local de trabalho.
  
  Além da notável durabilidade, as esquadrias de alumínio oferecem benefícios funcionais significativos. Contribuem para o isolamento térmico e acústico, assegurando um ambiente interno mais confortável e protegido contra ruídos externos. A facilidade de manutenção e limpeza é outro destaque, demandando apenas um esforço mínimo para mantê-las em condições impecáveis. Escolher esquadrias de alumínio é investir em praticidade, estética e qualidade para seus espaços.`],
  six: ['Guarda Corpo', 'guardaCorpo', `Descubra a segurança e o estilo dos guarda-corpos em nossa vidraçaria. Com designs modernos e elegantes, nossos guarda-corpos são ideais para proporcionar proteção e embelezar seus espaços
  Nossos guarda-corpos são fabricados com materiais de alta qualidade, garantindo resistência e durabilidade. Disponíveis em diferentes estilos, como guarda-corpos de vidro, aço inoxidável ou alumínio, oferecemos opções que se adequam perfeitamente às suas necessidades e ao design de sua residência, escritório ou espaço comercial.
  Além de fornecer segurança, os guarda-corpos também adicionam um toque de sofisticação aos ambientes. Eles são projetados para delimitar áreas, como escadas, varandas e terraços, proporcionando uma sensação de proteção sem bloquear a vista ou a entrada de luz natural. Com uma variedade de acabamentos e designs, você pode personalizar seu guarda-corpo para complementar perfeitamente a estética de seu espaço.
  Nossa equipe de profissionais está pronta para auxiliar na escolha do guarda-corpo ideal para o seu ambiente, considerando suas preferências estéticas e as exigências de segurança. Garantimos uma instalação segura e de alta qualidade, seguindo todas as normas e regulamentos aplicáveis.`],
  // seven: ['Vidros Temperados', 'temperado'],
  // eight: ['Cortina de Vidro', 'cortina'],
  // nine: ['Fechamentos de Vidro', 'fechamento'],
  ten: ['Trabalhos gerais', 'outras'],
};

export const buttonImages: { [key: string]: string[] } = {
  espelhos: ['https://imgur.com/gKMV8MJ.jpg', 'https://imgur.com/IhSTEUd.jpg', 'https://imgur.com/cu64eiZ.jpg', 'https://imgur.com/fBqQsl2.jpg', 'https://imgur.com/XqsWCGt.jpg', 'https://imgur.com/uTL5VG2.jpg', 'https://imgur.com/OBAeq8O.jpg', 'https://imgur.com/BAmw5BV.jpg', 'https://imgur.com/nIxGIo6.jpg'],
  box: ['https://imgur.com/GAXNjhB.jpg', 'https://imgur.com/hMR3zob.jpg', 'https://imgur.com/3b9w3Ab.jpg', 'https://imgur.com/ir62Rfb.jpg', 'https://imgur.com/b4mNbZF.jpg', 'https://imgur.com/Ibaf5ih.jpg', 'https://imgur.com/zXc3FyA.jpg'],
  portaDeVidro: ['https://imgur.com/zJdfPGZ.jpg', 'https://imgur.com/V4vZsjf.jpg', 'https://imgur.com/v45DOE7.jpg'],
  janelaDeVidro: ['https://imgur.com/OSMG4wu.jpg'],
  janelaDeAlum: ['https://imgur.com/MYxRE72.jpg', 'https://imgur.com/qdc3tgb.jpg', 'https://imgur.com/l6KDqdx.jpg', 'https://imgur.com/Sc33A5D.jpg'],
  guardaCorpo: ['https://imgur.com/93y4Y4o.jpg', 'https://imgur.com/P41b803.jpg'],
  outras: ['https://imgur.com/e8akWGJ.jpg', 'https://imgur.com/TYzw2g7.jpg', 'https://imgur.com/1cEapuq.jpg', 'https://imgur.com/yMZgPWJ.jpg',
  'https://imgur.com/HHYO1Dc.jpg', 'https://imgur.com/RPZv37d.jpg', 'https://imgur.com/XM8eg0M.jpg', 'https://imgur.com/lbDqxg3.jpg', 'https://imgur.com/SaBeKRm.jpg', 'https://imgur.com/dtxbiIv.jpg', 'https://imgur.com/8ObaEQl.jpg', 'https://imgur.com/Tzf0639.jpg']

};