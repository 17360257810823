export default function Contacts(): JSX.Element {
    return (
        <div className='flex justify-evenly items-center max-w-full mb-28' id="contacts">
            <span className="text-2xl font-bold text-center ml-8">Contatos</span>
                <div className="flex items-center justify-center">
                    <img src="https://imgur.com/L6XWgzH.png" alt="icone do whatsapp" className="w-[15%]" />
                    <span className="text-lg ml-4">(21) 98471-9548</span>
                </div>
                <div className="flex items-center justify-center">
                    <img src="https://imgur.com/m3rZKGs.png" alt="icone do whatsapp" className="w-[15%]" />
                    <span className="text-lg ml-4">vidroluz.rio@gmail.com</span>
                </div>
        </div>
    );
}