export default function VidroluzProperties(): JSX.Element {
    return (
        <div className="flex flex-col mb-28">
            <div className="flex justify-center">
                <div className="flex items-center justify-center mb-28 text-[#FFFFFF]">
                    <div className="flex flex-col items-center justify-center bg-[#1F2937] p-4 ml-10 mr-6 w-[1/3] h-[450px] rounded-xl hover:bg-[#0b0f14] hover:duration-500 hover:shadow-lg hover:shadow-gray-600">
                        <img alt="xp" height={150} width={150} src="https://imgur.com/SqwzLzR.png" />
                        <h3 className="mt-4 mb-2 font-semibold text-2xl">Experiência</h3>
                        <span className="mt-1 text-lg text-justify w-[90%] font-light">Com mais de 20 anos de experiência, nossa equipe experiente está sempre atualizada com as últimas tendências e tecnologias para oferecer as melhores opções aos nossos clientes.</span>
                    </div>
                    <div className="flex flex-col items-center justify-center bg-[#1F2937] p-4 mr-6 w-[1/3] h-[450px] rounded-xl hover:bg-[#0b0f14] hover:duration-500 hover:shadow-lg hover:shadow-gray-600">
                        <img alt="xp" height={150} width={150} src="https://imgur.com/ycRbibp.png" />
                        <h3 className="mt-4 mb-2 font-semibold text-2xl">Qualificação</h3>
                        <span className="mt-1 text-lg text-justify w-[90%] font-light">Nossa equipe é formada por profissionais capacitados com habilidades necessárias para fornecer soluções personalizadas e de alta qualidade conforme às necessidades de nossos clientes.</span>
                    </div>
                    <div className="flex flex-col items-center justify-center bg-[#1F2937] p-4 w-[1/3] mr-10 h-[450px] rounded-xl hover:bg-[#0b0f14] hover:duration-500 hover:shadow-lg hover:shadow-gray-600">
                        <img alt="xp" height={150} width={150} src="https://imgur.com/ZGwrOze.png" />
                        <h3 className="mt-4 mb-2 font-semibold text-2xl">Atendimento</h3>
                        <span className="mt-1 text-lg text-justify w-[85%] font-light">Nossa equipe está sempre disponível para responder a quaisquer perguntas ou preocupações que possam surgir durante o processo, garantindo a satisfação total do cliente.</span>
                    </div>
                </div>
            </div>
            <div className="flex bg-[#1F2937] justify-center pt-8 pb-8" id="aboutus">
                <div className="flex flex-col bg-[#1F2937] w-[60%] text-white text-center text-lg">
                    <span className="mb-2">
                    Bem-vindo(a) à Vidroluz , uma escolha certa para serviços de vidraçaria e esquadrias de alumínio no Rio de Janeiro! Desde 2000, oferecemos soluções de alta qualidade para nossos clientes, entregando resultados exclusivos e duradouros.
                    </span>
                    <span className="mb-2">
                    Nosso compromisso é fornecer serviços personalizados e atendimento de primeira classe, desde a concepção até a instalação final, garantindo que todos os nossos clientes fiquem satisfeitos com os resultados. Como uma das principais empresas de vidraçaria e esquadrias de alumínio do Rio de Janeiro, estamos prontos para ajudá-lo(a) a tornar sua visão em realidade.
                    </span>
                    <span className="mb-2">
                    Nossa equipe altamente treinada e experiente trabalha com você para fornecer soluções personalizadas que atendam às suas necessidades. Com uma ampla gama de produtos e serviços, como guarda corpo, coberturas em poliuretano, box para banheiro, espelhos, fechamento de varanda, entre outros, temos tudo o que você precisa para dar vida ao seu projeto.
                    </span>
                    <span className="mb-2">
                    Se você está procurando por uma empresa de vidros e alumínio confiável e experiente no Rio de Janeiro e em todo o estado, não procure mais do que a Vidroluz. Entre em contato conosco hoje mesmo para saber mais sobre nossos serviços personalizados.
                    </span>
                    <span className="font-semibold">Atendemos no Rio e Grande Rio</span>
                </div>
            </div>
        </div>
    )
}