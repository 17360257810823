import { createTheme } from '@mui/material/styles';

export const theme = createTheme ({
  palette: {
    primary: {
      main: '#3E4095',
    },
    secondary: {
      main: '#ececec',
    },
    background: {
      default: '#f2f2f2',
      paper: '#f2f2f2',
    },
    text: {
      primary: '#F2F2F2',
      secondary: '#000000'
    },
  },
  typography: {
    h1: {
      letterSpacing: '0em',
      lineHeight: 0.86,
      fontWeight: 500,
    },
    h5: {
      fontWeight: 100,
      lineHeight: 1.86,
      letterSpacing: '-0.04em',
    },
    h4: {
      fontSize: '1.5rem',
      fontWeight: 100,
    },
  },
})