import { createContext } from 'react';

interface GlobalContextValue {
  serviceSlide: string;
  setServiceSlide: (value: string) => void;
}

const globalContext = createContext<GlobalContextValue>({
  serviceSlide: '',
  setServiceSlide: () => {},
});

export default globalContext;