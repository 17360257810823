import FadeMUI from "@mui/material/Fade";
import Header from "./components/Header";
import Slide from "./components/Slide";
import VidroluzProperties from "./components/VidroluzProperties";
import ServicesType from "./components/ServicesType";
import GlobalProvider from './context/ContextProvider';
import Contacts from "./components/contacts";
import Footer from "./components/footer";

function App() {
  return (
    <GlobalProvider>
      <FadeMUI in={true} mountOnEnter unmountOnExit timeout={1000}>
          <div>
            <Header />
            <Slide />
            <VidroluzProperties />
            <ServicesType />
            <Contacts />
            <Footer />
          </div>
      </FadeMUI>
    </GlobalProvider>
  );
}

export default App;
